import {
    GET_STATES_BY_COUNTRIES,
   GET_ALL_COUNTRIES,
   GET_CITIES_BY_STATES_AND_COUNTRIES
  } from "../types";
  
  //default state
  const initialState = {

    countries: [],
    states: [],
    cities: [],
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ALL_COUNTRIES:
        {
          let newState = {...state, countries: action.payload};
          return newState;
        }
    case GET_STATES_BY_COUNTRIES:
        {
          let newState = {...state, states: action.payload};
          return newState;
        }
    case GET_CITIES_BY_STATES_AND_COUNTRIES:
        {
          let newState = {...state, cities: action.payload};
          return newState;
        }
      default: return state;
    }
  }