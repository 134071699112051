import axios from "axios";
import React from "react";
import { useSnackbar } from 'react-simple-snackbar'
import jwt_decode from 'jwt-decode';
import { CLEAR_LOADER, SET_LOADER, SET_CURRENT_USER, URL_PATH } from "../types";
import setAuthToken from "../../utils/setAuthToken";
import { TRADEHOOKS_URL , CUSTOMER_SERVER} from "./../../ServerUrls";
import { useNavigate } from 'react-router-dom'

import store from '../store';


/********************************
 * @DESC - USER LOGIN
 ********************************/

export const user_login = (formData) => async dispatch => {
  
  try {
    //dispatch -> dispatching data to the reducer
    dispatch({ type: SET_LOADER }); 
    //to make the loader visible since api call is being done
    let { data } = await axios.post(`${TRADEHOOKS_URL}/customer/login`, formData);
    if (data.status) {
      let { token } = data;
      localStorage.clear();
      localStorage.setItem("token", token);
      
      setAuthToken(token)
      let decoded_token = jwt_decode(token);
      console.log('decoded token', decoded_token);
      
      dispatch({ type: SET_CURRENT_USER, payload: decoded_token });
      dispatch(set_url_path(`/`));
      dispatch({ type: CLEAR_LOADER });
      
    }
    return true;
  } catch (err) {
    console.log('error',err.message);
    dispatch({ type: CLEAR_LOADER }); 
    return false;
    
  }
};

export const user_logout = () => async dispatch => {
  try{

    console.log('logout')
    localStorage.removeItem('token');
    window.location.href = '/login'
  } catch(err){
    console.log(err)
  }
}


export const set_url_path = payload => async dispatch => {
  dispatch({
    type: URL_PATH, payload: payload
  });
  dispatch({ type: CLEAR_LOADER });
};


export const check_login_token = async () => {
  if( localStorage.token ){
    let { data } = await axios.get(`${CUSTOMER_SERVER}/customers/refresh-token`);
    let old_token = jwt_decode(localStorage.getItem(`token`));
    let new_token = jwt_decode(data.token);
    if( old_token.isLoggedToken !== new_token.isLoggedToken ){
      window.location.href = `/`;
      localStorage.clear();
      user_logout();
    }
  }
}

export const check_session_expiry = async dispatch => {
  if( localStorage.token ){
    let token = localStorage.getItem(`token`);
    let payload = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (payload.exp < currentTime) {
      user_logout();
      window.location.href = `/`;
      localStorage.clear();
    } else {
        setAuthToken(token);
        store.dispatch(set_current_user(payload));
    }
  }
};

export const set_current_user = payload => async dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: payload
  });
  dispatch({ type: CLEAR_LOADER });
  return true;
};

export const update_profile = (profile_data) => async dispatch => {
  dispatch({ type: SET_LOADER }); 
  try{
    let {data} = axios.post('http://localhost:5002/customers/update-profile', profile_data);
    if(data.status){
      dispatch({ type: CLEAR_LOADER }); 
    }
  } catch(err){
    console.log(err);
    dispatch({ type: CLEAR_LOADER }); 
  }
}