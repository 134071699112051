import {
    SET_LOADER,
    CLEAR_LOADER,
   GET_ALL_CATEGORIES,
   GET_ALL_SUBCATEGORIES,
   GET_ALL_SUBCATEGORIES_BY_CATEGORY
  } from "../types";
  
  //default state
  const initialState = {

    categories: [],
    subCategories: [],
    subCategoriesByCategory: [],
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ALL_CATEGORIES:
        {
          let newState = {...state, categories: action.payload};
          return newState;
        }
       
      case GET_ALL_SUBCATEGORIES:
        {
          let newState = {...state, subCategories: action.payload};
          return newState;
        }
      case GET_ALL_SUBCATEGORIES_BY_CATEGORY:
          {
            let newState = {...state, subCategoriesByCategory: action.payload};
            return newState;
          }
      default: return state;
    }
  }