import {
    GET_ALL_CURRENCIES, GET_ALL_UNITS
  } from "../types";
  
  //default state
  const initialState = {

    currencies: [],
    units: [],
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ALL_CURRENCIES:
        {
          let newState = {...state, currencies: action.payload};
          return newState;
        }
    case GET_ALL_UNITS:
        {
          let newState = {...state, units: action.payload};
          return newState;
        }
     
      default: return state;
    }
  }