import {
   GET_OFFERED_BIDS,
   GET_DECLINED_BIDS,
   GET_CANCELLED_BIDS,
   GET_WITHDRAWN_BIDS,
   GET_IN_PROGRESS_BIDS,
   GET_TRADED_BIDS
  } from "../types";
  
  //default state
  const initialState = {
   offered: [],
   inProgress: [],
   traded: [],
   cancelled: [],
   declined:[],
   withdrawn: []
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      
        case GET_OFFERED_BIDS:
            console.log('payload', payload)
        return {
            ...state,
            offered : payload, //offered bids 
        }
        case GET_CANCELLED_BIDS:
        return {
            ...state,
            cancelled : payload, //cancelled bids 
        }
        case GET_DECLINED_BIDS:
        return {
            ...state,
            declined : payload, //declined bids
        }
        case GET_IN_PROGRESS_BIDS:
        return {
            ...state,
            inProgress : payload, //in progress bids 
        }
        case GET_WITHDRAWN_BIDS:
        return {
            ...state,
            withdrawn : payload, //withdrawn bids 
        }
        case GET_TRADED_BIDS:
        return {
            ...state,
            traded : payload, //traded bids
        }
      default: return state;
    }
  }
  