import {
   GET_MESSAGES,
   GET_CHAT_POSTS,
   GET_CHATS
   } from "../types";


   const initialState = {
    chatPosts:{},
    chats:{},
    messages:[]
   };


   export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      
       
        case GET_CHAT_POSTS:
        return {
            ...state,
            chatPosts: payload, //cancelled posts 
        }
        case GET_CHATS:
            return{
                ...state,
                chats: payload
            }
        case GET_MESSAGES:
            return{
                ...state,
                messages: payload
            }
        
      default: return state;
    }
  }
  