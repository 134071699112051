import './App.css';
import React ,{lazy, Suspense} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Switch
} from "react-router-dom";

import SnackbarProvider from 'react-simple-snackbar'

//to make data accessible in every component
import { Provider } from "react-redux";
import { check_session_expiry, check_login_token } from "./store/actions/authActions";
import store from "./store/store"
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from 'jwt-decode';
import {useDispatch, useSelector} from 'react-redux';
const Login = lazy( () => import('./Components/Authorisation/Login/Login'));
const CreatePost = lazy( () => import('./Components/CreatePost/CreatePost'));
const HomePage = lazy( () => import('./Components/HomePage/HomePage'));
const Balance = lazy( () => import('./Components/Payments/Balance'));
const UpdateWallet = lazy( () => import('./Components/Payments/UpdateWallet'));
const Bookmarks = lazy( () => import('./Components/Market/Bookmarks'));
const Settings = lazy( () => import('./Components/Market/Settings'));
const Listing = lazy( () => import('./Components/MyPosts/Listing'));
const Offered = lazy( () => import('./Components/MyOffers/Offered'));
const InProgress = lazy( () => import('./Components/MyPosts/InProgress'));
const InProgressOffered = lazy( () => import('./Components/MyOffers/InProgressOffered'));
const Completed = lazy( () => import('./Components/MyPosts/Completed'));
const Traded = lazy( () => import('./Components/MyOffers/Traded'));
const SelfCancelled = lazy( () => import('./Components/MyPosts/SelfCancelled'));
const OfferCancelled = lazy( () => import('./Components/MyOffers/OfferCancelled'));
const Withdrawn = lazy( () => import('./Components/MyPosts/Withdrawn'));
const OfferWithdrawn = lazy( () => import('./Components/MyOffers/OfferWithdrawn'));
const Archived = lazy( () => import('./Components/MyPosts/Archived'));
const Declined = lazy( () => import('./Components/MyPosts/Declined'));
const OfferDeclined = lazy( () => import('./Components/MyOffers/OfferDeclined'));
const Ledger = lazy( () => import('./Components/Payments/Ledger'));
const HubProfile = lazy( () => import('./Components/Setting/HubProfile'));
const Support = lazy( () => import('./Components/Setting/Support'));
const Messages = lazy( () => import('./Components/Inbox/Messages'));
const Alerts = lazy( () => import('./Components/Inbox/Alerts'));
const ChatPosts = lazy( () => import('./Components/ChatBox/ChatPosts'));
const ChatUsers = lazy( () => import('./Components/ChatBox/ChatUsers'));
const PrivateRoute = lazy(() => import("./utils/PrivateRoute")) ;

const loaderimage = 'https://davistexteditor.s3.amazonaws.com/tech/loader.svg';

if (localStorage.getItem('token')) {
  check_session_expiry();
} else {
  if(!window.location.pathname==='/login'){
    window.location.href='/login'
  }
}



function App() {
  

 
  return (
    <Provider store={store}>
      <SnackbarProvider>
    <Router>
    <Suspense fallback={
            <div className='loader_main_container_display'>
              <img src={loaderimage} alt="LoaderImageSequence" />
            </div>
          }>
      <Routes>
     
        <Route path="/" element={<HomePage />} />

        <Route exact path="/createPost" element={<CreatePost />} />
        <Route exact path="/editPost/:post_id" element={<CreatePost />} />
        <Route path="/listing" element={<Listing/>} />
        <Route path="/inProgress" element={<InProgress/>} />
        <Route path="/completed" element={<Completed/>} />
        <Route path="/selfCancelled" element={<SelfCancelled/>} />
        <Route path="/withdrawn" element={<Withdrawn/>} />
        <Route path="/archived" element={<Archived/>} />
        <Route path="/declined" element={<Declined/>} />

        <Route path="/offered" element={<Offered/>} />
        <Route path="/inProgressOffered" element={<InProgressOffered/>} />
        <Route path="/traded" element={<Traded/>} />
        <Route path="/offerCancelled" element={<OfferCancelled/>} />
        <Route path="/offerWithdrawn" element={<OfferWithdrawn/>} />
        <Route path="/offerDeclined" element={<OfferDeclined/>} />

        <Route path="/login" element={<Login/>} />
        <Route path="/bookmarks" element={<Bookmarks/>} />
        <Route path="/settings" element={<Settings/>} />
        
        <Route path="/ledger" element={<Ledger/>} />
        <Route path="/balance" element={<Balance/>} />
        <Route path="/updateWallet" element={<UpdateWallet/>} />

        <Route path="/messages" element={<Messages/>} />
        <Route path="/alerts" element={<Alerts/>} />

        <Route path="/hubProfile" element={<HubProfile/>} />
        <Route path="/support" element={<Support/>} />
        
        
        <Route path="/chatPosts" element={<ChatPosts/>} />
        <Route path="/chatUser" element={<ChatUsers/>} />
      
     </Routes>
     </Suspense>
    </Router>
    </SnackbarProvider>
    </Provider>
    );
}

export default App;
