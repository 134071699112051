export const SET_LOADER = 'SET_LOADER';
export const CLEAR_LOADER = 'CLEAR_LOADER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const CREATE_POST = 'CREATE_POST';


//posts
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_ALL_POSTS_OF_A_USER = "GET_ALL_POSTS_OF_A_USER";
export const GET_ALL_BOOKMARKED_POSTS = "GET_ALL_BOOKMARKED_POSTS";
export const GET_ALL_ARCHIVED_POSTS = "GET_ALL_ARCHIVED_POSTS";
export const GET_ALL_BIDS_FOR_A_POST = "GET_ALL_BIDS_FOR_A_POST"
//currency
export const GET_ALL_CURRENCIES = "GET_ALL_CURRENCIES";
export const GET_ALL_UNITS = "GET_UNITS";

//CATEGORIES
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_SUBCATEGORIES_BY_CATEGORY = "GET_ALL_SUBCATEGORIES_BY_CATEGORY";
export const GET_ALL_SUBCATEGORIES= "GET_ALL_SUBCATEGORIES";
export const GET_CURRENCY = "GET_CURRENCY";

//Country, state, cities
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";
export const GET_STATES_BY_COUNTRIES = "GET_STATES_BY_COUNTRIES";
export const GET_CITIES_BY_STATES_AND_COUNTRIES = "GET_CITIES_BY_STATES_AND_COUNTRIES";
export const URL_PATH='URL_PATH'

//Wallet
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";


//MyOffers
export const GET_OFFERED_BIDS = "GET_OFFERED_BIDS";
export const GET_IN_PROGRESS_BIDS = "GET_IN_PROGRESS_BIDS";
export const GET_TRADED_BIDS = "GET_TRADED_BIDS";
export const GET_CANCELLED_BIDS = "GET_CANCELLED_BIDS";
export const GET_WITHDRAWN_BIDS = "GET_WITHDRAWN_BIDS";
export const GET_DECLINED_BIDS = "GET_DECLINED_BIDS";


//MyPosts

export const GET_IN_PROGRESS_POSTS = "GET_IN_PROGRESS_POSTS";
export const GET_COMPLETED_POSTS = "GET_COMPLETED_POSTS";
export const GET_CANCELLED_POSTS = "GET_CANCELLED_POSTS";
export const GET_WITHDRAWN_POSTS = "GET_WITHDRAWN_POSTS";
export const GET_DECLINED_POSTS = "GET_DECLINED_POSTS";

//chats
export const GET_CHAT_POSTS = "GET_CHAT_POSTS";
export const GET_CHATS = "GET_CHATS"
export const GET_MESSAGES = "GET_MESSAGES";