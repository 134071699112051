//Production
export const ADMIN_SERVER= 'https://dev.app.admin.davisindex.com'
//export const CUSTOMER_SERVER = 'https://app.davisindex.com';

// //Developement
//export const ADMIN_SERVER= 'https://dev.app.admin.davisindex.com'
export const CUSTOMER_SERVER = "https://dev.app.davisindex.com";

// // //localhost
//export const SERVER_NAME= 'http://localhost:5001';
//export const SERVER_TWO = 'http://localhost:5002';

export const TRADEHOOKS_URL = "https://api.tradehooks.com";
