import {
  SET_LOADER,
  CLEAR_LOADER,
  SET_CURRENT_USER,
  URL_PATH
} from "../types";

//default state
const initialState = {
  loader: false,
  success: false,
  user: {},
  isAuthenticated: false,
  url_path:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loader: true
      };
      case CLEAR_LOADER:
      return {
        ...state,
        loader: false
      };
      case URL_PATH:
            return {
                ...state,
                url_path : action.payload,
      }
      case SET_CURRENT_USER:
       let newState = {...state, user: action.payload, isAuthenticated: true};
       console.log('newstate', newState);
       return newState;
    default: return state;
  }
}
