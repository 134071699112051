import {
    SET_LOADER,
    CLEAR_LOADER,
    CREATE_POST,
    GET_ALL_POSTS,
    GET_ALL_BOOKMARKED_POSTS,
    GET_ALL_ARCHIVED_POSTS,
    GET_ALL_POSTS_OF_A_USER,
    GET_ALL_BIDS_FOR_A_POST
  } from "../types";
  
  //default state
  const initialState = {
    loader: false,
    success: false,
    formData: [],
    posts: [],
    bookmarked_posts: [],
    archived_posts: [],
    user_posts: [],
    bids_for_a_post: []
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SET_LOADER:
        return {
          ...state,
          loader: true
        };
        case CLEAR_LOADER:
        return {
          ...state,
          loader: false
        };
        case CREATE_POST:
        return {
            ...state,
            formData : payload, //FORM data 
        }
        case GET_ALL_POSTS:
        return {
            ...state,
            posts : payload, //FORM data 
        }
        case GET_ALL_BOOKMARKED_POSTS:
        return {
            ...state,
            bookmarked_posts : payload, //FORM data 
        }
        case GET_ALL_ARCHIVED_POSTS:
        return {
            ...state,
            archived_posts : payload, //FORM data 
        }
        case GET_ALL_POSTS_OF_A_USER:
        return {
            ...state,
            user_posts : payload, //FORM data 
        }
        case GET_ALL_BIDS_FOR_A_POST:
        return {
            ...state,
            bids_for_a_post : payload, //FORM data 
        }
      default: return state;
    }
  }
  