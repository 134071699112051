import { combineReducers } from 'redux';
import authReducer from "./authReducer";
import postReducer from "./postReducer";
import createPostReducer from './createPostReducer';
import currenyAndUnitReducer from './currenyAndUnitReducer';
import countryStateCityReducer from './countryStateCityReducer';
import walletReducer from './walletReducer';
import myOffersReducer from './myOffersReducer';
import myPostsReducer from './myPostsReducer';
import chatReducer from './chatReducer';

export default combineReducers({
    auth: authReducer,
    postReducer,
    createPostReducer,
    currencyAndUnits: currenyAndUnitReducer,
    countryStateCity: countryStateCityReducer,
    wallet: walletReducer,
    myOffers: myOffersReducer,
    myPosts: myPostsReducer,
    chatsStore: chatReducer
})