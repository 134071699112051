import {

    GET_DECLINED_POSTS,
    GET_CANCELLED_POSTS,
    GET_WITHDRAWN_POSTS,
    GET_IN_PROGRESS_POSTS,
    GET_COMPLETED_POSTS
   } from "../types";


   const initialState = {
    inProgress: [],
    completed: [],
    cancelled: [],
    declined:[],
    withdrawn: []
   };


   export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      
       
        case GET_CANCELLED_POSTS:
        return {
            ...state,
            cancelled : payload, //cancelled posts 
        }
        case GET_DECLINED_POSTS:
        return {
            ...state,
            declined : payload, //declined posts
        }
        case GET_IN_PROGRESS_POSTS:
        return {
            ...state,
            inProgress : payload, //in progress posts 
        }
        case GET_WITHDRAWN_POSTS:
        return {
            ...state,
            withdrawn : payload, //withdrawn posts 
        }
        case GET_COMPLETED_POSTS:
        return {
            ...state,
            completed : payload, //traded posts
        }
        
      default: return state;
    }
  }
  