import axios from 'axios';

const setAuthToken = token => {
    if(token){
        //Apply to very request
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        //Delete Auth Token
        delete axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthToken;
